import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { baseUrl } from '../config';
import { loginToken } from '../utils/local-storage-utils';

export const orderApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  reducerPath: 'orderApi',
  refetchOnReconnect: true,
  tagTypes: ['Order'],

  endpoints: (builder) => ({
    getAllOrders: builder.query({
      query: ({ ordersListReducer }) => {
        const { search, __eq_status, ...restParams } = ordersListReducer.params;

        const searchParams = search ? { search } : {};
        const statusParams =
          __eq_status === 0
            ? { __eq_status: 0 }
            : __eq_status
            ? { __eq_status }
            : {};

        return {
          url: `api/orders`,
          method: 'GET',
          params: { ...searchParams, ...statusParams, ...restParams },
          headers: {
            Authorization: `Bearer ${loginToken()}`,
          },
        };
      },
      providesTags: ['Order'],
    }),

    getOrderDetail: builder.query({
      query: ({ id }) => ({
        url: `api/orders/${id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${loginToken()}`,
        },
      }),
      transformResponse: (response) => response.payload.data || {},
      providesTags: ['Order'],
      // keepUnusedDataFor: 3,
    }),

    updateOrder: builder.mutation({
      query: ({ values, id }) => {
        return {
          url: `api/orders/${id}`,
          method: 'PUT',
          body: values,
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            Authorization: `Bearer ${loginToken()}`,
          },
        };
      },
      invalidatesTags: ['Order'],
    }),

    createOrder: builder.mutation({
      query: (payload) => {
        return {
          url: 'api/orders',
          method: 'POST',
          body: payload,
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            Authorization: `Bearer ${loginToken()}`,
          },
        };
      },
      invalidatesTags: ['Order'],
    }),

    deleteOrder: builder.mutation({
      query: (id) => ({
        url: `api/orders/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${loginToken()}`,
        },
      }),
      invalidatesTags: ['Order'],
    }),

    getAdminOrderStats: builder.query({
      query: () => ({
        url: 'api/orders/stats',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${loginToken()}`,
        },
      }),
      // invalidatesTags: ['Order'],
    }),

    /*--------------------------- Customer Order fetch --------------------------------*/

    getAllCustomerOrders: builder.query({
      query: ({ customerOrdersListReducer }) => {
        const { search, __eq_status, ...restParams } =
          customerOrdersListReducer.params;

        const searchParams = search ? { search } : {};
        const statusParams =
          __eq_status === 0
            ? { __eq_status: 0 }
            : __eq_status
            ? { __eq_status }
            : {};

        return {
          url: 'api/customer/orders',
          method: 'GET',
          params: { ...searchParams, ...statusParams, ...restParams },
          headers: {
            Authorization: `Bearer ${loginToken()}`,
          },
        };
      },
      providesTags: ['Order'],
    }),

    createCustomerOrder: builder.mutation({
      query: (payload) => {
        return {
          url: 'api/customer/orders',
          method: 'POST',
          body: payload,
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            Authorization: `Bearer ${loginToken()}`,
          },
        };
      },
      invalidatesTags: ['Order'],
    }),

    getCustomerOrderStats: builder.query({
      query: () => ({
        url: 'api/customer/orders/stats',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${loginToken()}`,
        },
      }),
    }),
  }),
});
export const {
  useGetAllOrdersQuery,
  useCreateOrderMutation,
  useDeleteOrderMutation,
  useGetOrderDetailQuery,
  useUpdateOrderMutation,
  useGetAdminOrderStatsQuery,
  /*---------- Customer Order ------------*/
  useGetAllCustomerOrdersQuery,
  useCreateCustomerOrderMutation,
  useGetCustomerOrderStatsQuery,
} = orderApi;
