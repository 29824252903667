import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  params: {
    per_page: 25,
    page: 1,
    sort_order: 'desc',
    sort_id: `id`,
    search: '',
    __eq_status: '',
  },
  selectProductParams: {
    selected_product_id: '',
    search: '',
    per_page: 25,
  },
  limitOptions: [
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ],
  statusOptions: [
    { value: 1, label: 'Visible' },
    { value: 0, label: 'Invisible' },
  ],
  headerStatusOptions: [
    { value: 'active', label: 'Visible' },
    { value: 'inactive', label: 'Invisible' },
  ],
  formInitialValues: {
    name: '',
    platform: '',
    product_key: '',
    price: '',
    status: '',
    quantity: '',
    image: '',
    description: '',
    non_changeable: 0,
  },
  schedularLogsParams: {
    per_page: 25,
    page: 1,
    sort_order: 'desc',
    sort_id: `id`,
  },
};

export const ProductsListSlice = createSlice({
  name: 'productsListReducer',
  initialState,
  reducers: {
    productsListReducer: (state) => {
      state.params = initialState.params;
    },
    setDefaultProductParams: (state) => {
      state.params = initialState.params;
    },
    searchProduct: (state, action) => {
      state.params.search = action.payload;
    },
    toggleProductListFilter: (state, action) => {
      const name = action.payload.name;
      state.params[name] = action.payload.value;
      state.params['page'] = 1;
    },
    toggleSearchProductListFilter: (state, action) => {
      const name = action.payload.name;
      state.selectProductParams[name] = action.payload.value;
    },
    setDefaultProductListParam: (state, action) => {
      state.params = action.payload;
    },
    setDefaultForm: (state) => {
      state.formInitialValues = initialState.formInitialValues;
    },
    setPage: (state, action) => {
      state.params.page = action.payload;
    },
    setLogsPage: (state, action) => {
      console.log(action.payload)
      state.schedularLogsParams.page = action.payload;
    },
    setProductIdList: (state, action) => {
      state.selectProductParams['selected_product_id'] = action.payload;
    },
    setUpdateForm: (state, action) => {
      const {
        name,
        platform,
        product_key,
        price,
        status,
        product_inventory,
        image,
        description,
        non_changeable,
      } = action.payload;

      const updateState = {
        name: name,
        platform: platform,
        product_key: product_key,
        price: price,
        status: status,
        quantity: product_inventory?.quantity,
        image: image,
        description: description,
        non_changeable: non_changeable
      };
      Object.keys(state.formInitialValues).forEach((key) => {
        state.formInitialValues[key] = updateState[key];
      });
    },
  },
});

export const {
  productsListReducer,
  searchProduct,
  toggleProductListFilter,
  toggleSearchProductListFilter,
  setDefaultProductListParam,
  setDefaultProductParams,
  setDefaultForm,
  setUpdateForm,
  setPage,
  setLogsPage,
  setProductIdList,
} = ProductsListSlice.actions;

export default ProductsListSlice.reducer;
