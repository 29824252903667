import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { baseUrl } from '../config';
import { loginToken } from '../utils/local-storage-utils';

export const userApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  reducerPath: 'userAPi',
  refetchOnReconnect: true,
  tagTypes: ['User'],

  endpoints: (builder) => ({
    getAllUsers: builder.query({
      query: ({ params }) => {
        const { search, __eq_status, ...restParams } = params;

        const searchParams = search ? { search } : {};
        const statusParams =
          __eq_status === 0
            ? { __eq_status: 0 }
            : __eq_status
            ? { __eq_status }
            : {};

        return {
          url: `api/user`,
          method: 'GET',
          params: { ...searchParams, ...statusParams, ...restParams },
          headers: {
            Authorization: `Bearer ${loginToken()}`,
          },
        };
      },
      providesTags: ['User'],
    }),

    getUserDetail: builder.query({
      query: ({ id }) => ({
        url: `api/user/${id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${loginToken()}`,
        },
      }),
      transformResponse: (response) => response.payload.data || {},
      providesTags: ['User'],
      // keepUnusedDataFor: 3,
    }),

    enableDisableUser: builder.mutation({
      query: ({ STATUS, id }) => {
        return {
          url: `api/user/${id}/update/status`,
          method: 'PUT',
          body: STATUS,
          headers: {
            Authorization: `Bearer ${loginToken()}`,
          },
        };
      },
      invalidatesTags: ['User'],
    }),

    updateUser: builder.mutation({
      query: ({ fData, id }) => {
        const PARAMS = { _method: 'put' };
        return {
          url: `api/user/${id}`,
          method: 'POST',
          params: PARAMS,
          body: fData,
          headers: {
            Authorization: `Bearer ${loginToken()}`,
          },
        };
      },
      invalidatesTags: ['User'],
    }),

    restoreUser: builder.mutation({
      query: ({ payload }) => {
        const PARAMS = { _method: 'put' };
        return {
          url: `api/user/restore`,
          method: 'POST',
          params: PARAMS,
          body: payload,
          headers: {
            Authorization: `Bearer ${loginToken()}`,
          },
        };
      },
      invalidatesTags: ['User'],
    }),

    createUser: builder.mutation({
      query: (payload) => {
        return {
          url: 'api/user',
          method: 'POST',
          body: payload,
          headers: {
            // 'Content-type': 'application/json; charset=UTF-8',
            Authorization: `Bearer ${loginToken()}`,
          },
        };
      },
      invalidatesTags: ['User'],
    }),

    deleteUser: builder.mutation({
      query: (id) => ({
        url: `api/user/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${loginToken()}`,
        },
      }),
      invalidatesTags: ['User'],
    }),
  }),
});
export const {
  useGetAllUsersQuery,
  useCreateUserMutation,
  useDeleteUserMutation,
  useEnableDisableUserMutation,
  useGetUserDetailQuery,
  useUpdateUserMutation,
  useRestoreUserMutation,
} = userApi;
