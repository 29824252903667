export const loginToken = () => {
  const storeToken = localStorage.getItem('game_chest_token');
  return storeToken || null;
};

export const getLoginToken = () => {
  const storeToken = localStorage.getItem('game_chest_token');
  return storeToken || null;
};

/* Find out if anyone is logged in | @return boolean */
export const loggedIn = () => !!localStorage.getItem('game_chest_token');

export const loggedInUser = (data) => {
  localStorage.setItem('game_chest_token', data);
};

export const logOut = () => {
  localStorage.clear();
  // localStorage.removeItem("game_chest_token");
  window.location.href = '/login';
};

export const clearStorage = () => {
  localStorage.clear();
};
