import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import { loggedIn, getLoginToken } from './app/utils/local-storage-utils';
import { setLoginCredentials } from './pages/Login/LoginSlice';
import MainRoutes from './MainRoutes';
import MobileNotifier from './components/MobileNotifier';
import { publicRoutes } from './routes';
// import PageNotFound from './pages/PageNotFound';
import './resources/styles/global.scss';
import './resources/styles/scss/main.scss';

const App = () => {
  const dispatch = useDispatch();
  const LoginReducer = useSelector((state) => state.LoginReducer);
  const { loginToken } = LoginReducer; // token from reducer
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

  const accessToken = getLoginToken(); // token from local storage
  if (loggedIn()) {
    dispatch(setLoginCredentials(accessToken));
  }

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', changeWidth);
    return () => {
      window.removeEventListener('resize', changeWidth);
    };
  }, []);

  const Data = setScreenWidth;

  return (
    <>
      {screenWidth > 991 ? (
        <div className='App'>
          {loggedIn() || loginToken ? (
            <MainRoutes />
          ) : (
            <Routes>
              <Route path='*' element={<Navigate to='/login' replace />} />
              {/* <Route path='*' element={<PageNotFound />} /> */}

              {publicRoutes.map(({ path, Element }) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <React.Suspense fallback={null}>
                      <Element />
                    </React.Suspense>
                  }
                />
              ))}
            </Routes>
          )}
          <ToastContainer hideProgressBar={true} draggable={false} />
          <h1>{Data}</h1>
        </div>
      ) : (
        <MobileNotifier />
      )}
      <div />
    </>
  );
};

export default App;
