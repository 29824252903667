import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';

import Layout from './components/Layout';
import SideNavbar from './components/SideNavbar';
import { routes } from './routes';
import { useGetUserProfileQuery } from './app/services/loginApi';
import Loading from './components/Loading';
import { logOut } from './app/utils/local-storage-utils';

const MainRoutes = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [initialLoading, setInitialLoading] = useState(true);

  const { data, isSuccess, isLoading, error } = useGetUserProfileQuery({
    skip: true,
  });
  const role = data?.payload?.data?.roles[0]?.name;

  const loaderStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  useEffect(() => {
    if (isSuccess) {
      setInitialLoading(false);
    }
  }, [isSuccess]);

  if (error) {
    logOut();
  }

  if (isLoading && initialLoading) {
    return (
      <div style={loaderStyle}>
        <Loading />
      </div>
    );
  }
  if (pathname === '/') {
    navigate('/products');
  }
  return (
    <Layout role={role}>
      {role === 'admin' && <SideNavbar />}
      <Routes>
        {routes.map(({ path, Element, subRoutes, roles }) => {
          if (roles?.includes(role)) {
            return (
              <Route
                key={path}
                path={path}
                element={
                  <React.Suspense
                    fallback={
                      <div style={loaderStyle}>
                        <Loading />
                      </div>
                    }
                  >
                    <Element />
                  </React.Suspense>
                }
              >
                {subRoutes?.map(({ path, Element, roles }) => {
                  if (roles?.includes(role)) {
                    return (
                      <Route
                        key={path}
                        path={path}
                        element={
                          <React.Suspense
                            fallback={
                              <div style={loaderStyle}>
                                <Loading />
                              </div>
                            }
                          >
                            <Element />
                          </React.Suspense>
                        }
                      />
                    );
                  }
                })}
              </Route>
            );
          }
        })}
      </Routes>
    </Layout>
  );
};

export default MainRoutes;
