import loader from '../../resources/images/loader.svg';
import whiteLoader from '../../resources/images/loader-white.svg';

const Loading = (props) => {
  const { height, width, xs, sm, md, lg, xl, className, color } = props;
  const setHeight =
    height || xs ? 15 : sm ? 25 : md ? 50 : lg ? 80 : xl ? 200 : 25;
  const setWidth =
    width || xs ? 15 : sm ? 25 : md ? 50 : lg ? 80 : xl ? 200 : 25;

  return (
    <img
      src={color === `white` ? whiteLoader : loader}
      alt='loader'
      className={className}
      height={setHeight}
      width={setWidth}
    />
  );
};
export default Loading;
