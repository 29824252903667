import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Gravatar from 'react-gravatar';

import { logOut } from '../../../app/utils/local-storage-utils';
import {
  useCreateLogoutMutation,
  useGetUserProfileQuery,
} from '../../../app/services/loginApi';
import { handleError } from '../../../app/helpers/errorHelpers';

import './userCard.scss';

const UserCard = () => {
  const [logoutClicked, setlogoutClicked] = useState(false);

  const { data } = useGetUserProfileQuery({
    skip: true,
  });

  const { first_name: firstName, email ,image} = data?.payload?.data || {};

  const [createLogout] = useCreateLogoutMutation();

  const onClickLogOut = (event) => {
    event.preventDefault();

    if (!logoutClicked) {
      setlogoutClicked(true);
      createLogout()
        .unwrap()
        .then(() => logOut())
        .catch((error) => {
          setlogoutClicked(false);
          handleError(error);
        });
    }
  };


  return (
    <div className='user-card'>
      <Link to='/my-account' className='user-avatar'>
        {/* <div className='bg-black rounded-circle avatar' /> */}
        <Gravatar email={email} className='bg-black rounded-circle avatar' src={image ? image : ''}/>
        <h5>{firstName}</h5>
      </Link>

      <div className='logout' onClick={onClickLogOut}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          fill='currentColor'
          className='bi bi-box-arrow-right'
          viewBox='0 0 16 16'
        >
          <path
            fillRule='evenodd'
            d='M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z'
          />
          <path
            fillRule='evenodd'
            d='M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z'
          />
        </svg>
      </div>
    </div>
  );
};

export default UserCard;
