import React from 'react';
const Users = React.lazy(() => import('../pages/Users'));
const UserForm = React.lazy(() => import('../pages/Users/UserForm'));
const UserContainer = React.lazy(() => import('../pages/Users/UserContainer'));

const userRoutes = {
  path: 'users',
  Element: Users,
  roles: ['admin'],
  subRoutes: [
    {
      path: 'users',
      Element: UserContainer,
      roles: ['admin'],
    },
    {
      path: 'create-users',
      Element: UserForm,
      roles: ['admin'],
    },
    {
      path: 'update-user/:id',
      Element: UserForm,
      roles: ['admin'],
    },
  ],
};

export default userRoutes;
