import React from 'react';
import { toast } from 'react-toastify';

import { isString } from '../utils/data-utils';
import 'react-toastify/dist/ReactToastify.css';
toast.configure();

export const handleError = (error) => {
  if (error) {
    toast.error(() => {
        if(typeof error.data.message === 'object') {
            return Object.keys(error.data.message).map((item, i) => {
                return <div key={i}>
                    <h6>Error {error.status}</h6>
                <p>{error.data.message[item][0]}</p>
            </div>
            })
        } else {
            return <div>
                <h6>Error {error.status}</h6>
                <p>{error.data.message}</p>
            </div>
        }
    });
  }
  // if (error) {
  //   if (!error.response) {
  //     const { data } = error;
  //     const { status, message } = data;
  //
  //     if (error.message) {
  //       toast.error(() => (
  //         <div>
  //           <h6>Error 500</h6>
  //           <p>{error.message}</p>
  //         </div>
  //       ));
  //     }
  //     if (error.originalStatus === 404 || error.status === "PARSING_ERROR") {
  //       toast.error(() => (
  //         <div>
  //           <h6>{error.status}</h6>
  //           <p>{error.originalStatus} Not Found</p>
  //         </div>
  //       ));
  //     }
  //     if (isString(message)) {
  //       toast.error(() => (
  //         <div>
  //           <h6>Error {status ? status : "500"}</h6>
  //           <p>{data.message}</p>
  //         </div>
  //       ));
  //     }
  //   } else {
  //     /* if response */
  //     const { response } = error;
  //     const { status, data } = response;
  //     if (status && data) {
  //       if (data[0] === `<`)
  //         toast.error(() => (
  //           <div>
  //             <h6>Error 500</h6>
  //             <p>Server Error</p>
  //           </div>
  //         ));
  //     } else if (status === 400) {
  //       if (isString(data.message)) {
  //         toast.error(() => (
  //           <div>
  //             <h6>Error {status}</h6>
  //             <p>{data.message}</p>
  //           </div>
  //         ));
  //       } else if (data.payload.message) {
  //         toast.error(() => (
  //           <div>
  //             <h6>Error {status}</h6>
  //             <p>{data.payload.message}</p>
  //           </div>
  //         ));
  //       }
  //     } else if (status === 401) {
  //       if (data.message && isString(data.message)) {
  //         toast.error(() => (
  //           <div>
  //             <h6>Error {status}</h6>
  //             <p>{data.message}</p>
  //           </div>
  //         ));
  //       } else if (data.payload.message && isString(data.payload.message)) {
  //         toast.error(() => (
  //           <div>
  //             <h6>Error {status}</h6>
  //             <p>{data.payload.message}</p>
  //           </div>
  //         ));
  //       }
  //     } else if (status === 403) {
  //       if (isString(data.message)) {
  //         toast.error(() => (
  //           <div>
  //             <h6>Error {status}</h6>
  //             <p>{data.message}</p>
  //           </div>
  //         ));
  //       }
  //       if (isString(data.data)) {
  //         toast.error(() => (
  //           <div>
  //             <h6>Error {status}</h6>
  //             <p>{data.data}</p>
  //           </div>
  //         ));
  //       } else {
  //         toast.error(() => (
  //           <div>
  //             <h6>Error {status}</h6>
  //             <p>Forbidden action.</p>
  //           </div>
  //         ));
  //       }
  //     } else if (status === 404) {
  //       if (isString(data.message.detail)) {
  //         toast.error(() => (
  //           <div>
  //             <h6>Error {status}</h6>
  //             <p>{data.message.detail}</p>
  //           </div>
  //         ));
  //       }
  //     } else if (status === 409) {
  //       if (isString(data.message)) {
  //         toast.error(() => (
  //           <div>
  //             <h6>Error {status}</h6>
  //             <p>{data.message}</p>
  //           </div>
  //         ));
  //       }
  //     } else if (status === 500) {
  //       if (data.error) {
  //         if (isString(data.error)) {
  //           toast.error(() => (
  //             <div>
  //               <h6>Error {status}</h6>
  //               <p>{data.error}</p>
  //             </div>
  //           ));
  //         }
  //       }
  //       if (isString(data.message)) {
  //         toast.error(() => (
  //           <div>
  //             <h6>Error {status}</h6>
  //             <p>{data.message}</p>
  //           </div>
  //         ));
  //       }
  //     }
  //   }
  // }
};

export const handle422Error = (error) => {
  if (error?.status === 422) {
    toast.error(() => (
      <div>
        <h6>Error {error.status}</h6>
        <p>{error.data.message.message[0]}</p>
      </div>
    ));
  }
};

export const displayError = (message) => {
  if (message) {
    toast.error(() => (
      <div>
        <p>{message}</p>
      </div>
    ));
  }
};

export const handleSuccess = (success) => {
  if (success) {
    if (isString(success)) {
      toast.success(() => (
        <div>
          <h6>Success</h6>
          <p>{success}</p>
        </div>
      ));
    } else {
      if (success.message) {
        toast.success(() => (
          <div>
            <h6>Success</h6>
            <p>{success.message}</p>
          </div>
        ));
      } else {
        if (success.data) {
          if (success.data.message) {
            toast.success(() => (
              <div>
                <h6>Success</h6>
                <p>{success.data.message}</p>
              </div>
            ));
          } else {
            toast.success(() => (
              <div>
                <h6>Success</h6>
                <p>{success.data.payload.message}</p>
              </div>
            ));
          }
        } else {
          toast.success(() => (
            <div>
              <h6>Success</h6>
              <p>{success.data.message}</p>
            </div>
          ));
        }
      }
    }
  }
};
