// import React from "react";
// const Products = React.lazy(() => import("../pages/Products"));
// const ProductContainer = React.lazy(() =>
//   import("../features/ProductContainer")
// );
// const AddProducts = React.lazy(() => import("../pages/AddProducts"));

// import ProductContainer from "../features/ProductContainer";
// import AddProducts from "../pages/AddProducts";
// import Products from "../pages/Products";

import React from 'react';

const ProductContainer = React.lazy(() =>
  import('../pages/Products/ProductContainer')
);
const Products = React.lazy(() => import('../pages/Products'));
const ProductForm = React.lazy(() => import('../pages/Products/ProductForm'));
const SchedularLogs = React.lazy(() => import('../pages/Products/SchedularLogs'));


const productRoutes = {
  path: 'products',
  Element: Products,
  roles: ['admin'],
  subRoutes: [
    {
      path: 'products',
      Element: ProductContainer,
      roles: ['admin'],
    },
    {
      path: 'add-products',
      Element: ProductForm,
      roles: ['admin'],
    },
    {
      path: 'update-product/:id',
      Element: ProductForm,
      strict: true,
      roles: ['admin'],
    },
    {
      path: 'schedular/logs',
      Element: SchedularLogs,
      strict: true,
      roles: ['admin'],
    },
  ],
};

export default productRoutes;
