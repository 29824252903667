import React from 'react';
const OrderContainer = React.lazy(() =>
  import('../pages/Orders/OrderContainer')
);
const Orders = React.lazy(() => import('../pages/Orders'));
// const PageNotFound = React.laxu(() => import('../pages/PageNotFound'));
const OrderForm = React.lazy(() => import('../pages/Orders/OrderForm'));

const orderRoutes = {
  path: 'orders',
  Element: Orders,
  roles: ['admin'],
  subRoutes: [
    {
      path: 'orders',
      Element: OrderContainer,
      roles: ['admin'],
    },
    // {
    //   path: '*',
    //   Element: PageNotFound,
    //   roles: ['admin'],
    // },
    {
      path: 'create-orders',
      Element: OrderForm,
      roles: ['admin'],
    },
    {
      path: 'update-order/:id',
      Element: OrderForm,
      roles: ['admin'],
    },
  ],
};

export default orderRoutes;
