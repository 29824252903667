import React from 'react';

import ForgetPassword from '../pages/ForgetPassword';

import productRoutes from './product';
import userRoutes from './user';
import orderRoutes from './order';

const Login = React.lazy(() => import('../pages/Login'));
// const ForgetPassword = React.lazy(() => import('../pages/ForgetPassword'));
const MyAccount = React.lazy(() => import('../pages/MyAccount'));
const CustomerOrders = React.lazy(() => import('../pages/CustomerOrder'));
const CustomerProducts = React.lazy(() => import('../pages/CustomerProducts'));
const ResetPassword = React.lazy(() => import('../pages/ResetPassword'));
const ActivateEmail = React.lazy(() => import('../pages/ActivateEmail'));
const PageNotFound = React.lazy(() => import('../pages/PageNotFound'));

export const publicRoutes = [
  {
    path: '/',
    Element: Login,
  },
  // {
  //   path: '/404',
  //   Element: PageNotFound,
  // },
  {
    path: '/login',
    Element: Login,
  },
  {
    path: 'forget-password',
    Element: ForgetPassword,
  },
  {
    path: 'reset-password/:token',
    Element: ResetPassword,
  },
  {
    path: 'reset-password/:token/:type',
    Element: ResetPassword,
  },
  {
    path: 'activate/:token',
    Element: ActivateEmail,
  },
];

export const routes = [
  {
    path: 'reset-password/:token',
    Element: ResetPassword,
    roles: ['customer', 'admin'],
  },
  {
    path: 'reset-password/:token/:type',
    Element: ResetPassword,
    roles: ['customer', 'admin'],
  },
  {
    path: 'activate/:token',
    Element: ActivateEmail,
    roles: ['customer', 'admin'],
  },
  {
    path: 'products',
    Element: CustomerProducts,
    roles: ['customer'],
  },
  {
    path: 'orders',
    Element: CustomerOrders,
    roles: ['customer'],
  },
  {
    path: 'my-account',
    Element: MyAccount,
    roles: ['admin', 'customer'],
  },
  {
    path: '*',
    Element: PageNotFound,
    roles: ['admin', 'customer'],
  },

  { ...productRoutes },
  { ...orderRoutes },
  { ...userRoutes },
];
