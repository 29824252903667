import { useLocation, Link } from 'react-router-dom';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { isActiveRoute } from '../../app/utils/data-utils';

import UserCard from './UserCard';

import './sideNav.scss';

const SideNavbar = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const userLinks = [
    { path: '/users', name: t('all_users') },
    { path: '/users/create-users', name: t('create_user') },
    { path: '/users/update-user/:id' },
  ];

  const ordersLinks = [
    { path: '/orders', name: t('all_orders') },
    { path: '/orders/create-orders', name: t('create_order') },
    { path: '/orders/update-order/:id' },
  ];

  const productsLinks = [
    { path: '/products', name: t('all_product') },
    { path: '/products/add-products', name: t('create_product') },
    { path: '/products/update-product/:id' },
    { path: '/products/schedular/logs', name: t('product_schedular_logs') },
  ];

  const myAccountPath = [{ path: '/my-account' }];

  const isUserActive = isActiveRoute(userLinks, pathname);
  const isOrderActive = isActiveRoute(ordersLinks, pathname);
  const isProductsActive = isActiveRoute(productsLinks, pathname);
  const isMyaccountActive = isActiveRoute(myAccountPath, pathname);

  const ActiveLink = isUserActive
    ? userLinks
    : isOrderActive
    ? ordersLinks
    : isProductsActive
    ? productsLinks
    : isMyaccountActive
    ? myAccountPath
    : [];

  return (
    <aside className='sideNav'>
      <div className='menu'>
        <ul className='list-group list-group-flush'>
          {ActiveLink.map(
            (element, id) =>
              element.name && (
                <li key={id}>
                  <Link
                    className={clsx('link', {
                      active: pathname === element.path,
                    })}
                    to={element.path}
                  >
                    {element.name}
                  </Link>
                </li>
              )
          )}
        </ul>
        <UserCard />
      </div>
    </aside>
  );
};

export default SideNavbar;
