import { matchPath } from 'react-router-dom';

export function isString(variable) {
  return typeof variable === 'string';
}

export const ucFirst = (value) => {
  if (value && isString(value)) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }
};

export const ucFirstAll = (value, separator) => {
  if (value && isString(value)) {
    const part = value.trim().split(separator || ` `);
    if (part.length === 1) return ucFirst(value);
    let str = ``;
    part.map((p) => (str += `${ucFirst(p)} `));
    return str.trim();
  }
};

export function unslufigy(value) {
  if (value.includes(`-`)) {
    const part = value.split(`-`);
    let str = '';
    part.map((p) => (str += `${ucFirst(p)} `));
    return str.trim();
  } else if (value.includes(`_`)) {
    const part = value.split(`_`);
    let str = '';
    part.map((p) => (str += `${ucFirst(p)} `));
    return str.trim();
  }
  return ucFirst(value);
}

export function isActiveRoute(data, pathName) {
  return (
    data.filter((path) => {
      return matchPath(path, pathName);
    }).length > 0
  );
}

export function getStatusValue(selectedOption) {
  const { value } = selectedOption || '';
  const VALUE = value === 'active' ? 1 : value === 'inactive' ? 0 : '';
  const STATUS = '__eq_status';
  const payload = { name: STATUS, value: VALUE };
  return payload;
}

export function fileExtension(filename) {
  return filename.split('.').pop();
}

export function search(id, arr) {
  return arr.filter((obj) => obj.value === id);
}

export function onlyNumberValidation(value) {
  return value.match(/^[0-9]+$/);
}

export function onlyDecimalValidation(value) {
  return value.match(/([0-9]+[.]*)+$/);
}

export const fullDateFormat = (data) => {
  const date = new Date(data);
  const isoDate = date.toISOString();
  return `${isoDate.substr(0, 10)} ${isoDate.substr(11, 8)}`;
};
