import clsx from 'clsx';
import { Link } from 'react-router-dom';

import Loading from '../../Loading';
import './button.scss';

const Button = (props) => {
  const {
    color = 'black',
    disabled,
    label,
    to,
    variant = 'solid',
    processing,
    rounded,
    roundedBig,
    type = 'submit',
    onClick,
    dataAttr,
    className,
  } = props;

  /*
   * variant : outline | solid
   * color : black | white | blue
   * */

  const btnVariant = `btn--${variant}`;
  const btnColor = `btn--${color}`;

  return type === 'link' ? (
    <Link
      to={to}
      className={clsx('theme-btn', btnVariant, btnColor, className, {
        'is-rounded': rounded,
        roundedBig,
        processing,
      })}
      disabled={disabled}
      onClick={onClick}
    >
      <span>{label}</span>
    </Link>
  ) : (
    <button
      type={type}
      className={clsx('theme-btn', btnVariant, btnColor, className, {
        'is-rounded': rounded,
        'is-disabled': disabled,
        roundedBig,
        processing,
      })}
      disabled={!!disabled}
      onClick={onClick}
      {...dataAttr}
    >
      <span>{label}</span>
      {processing ? <Loading /> : null}
    </button>
  );
};

export default Button;
