import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { baseUrl } from '../config';
import { loginToken } from '../utils/local-storage-utils';

export const productApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  reducerPath: 'productAPi',
  refetchOnReconnect: true,
  tagTypes: ['Product'],

  endpoints: (builder) => ({
    getAllProducts: builder.query({
      query: ({ productsListReducer }) => {
        const { search, __eq_status, __in_id, ...restParams } =
          productsListReducer.params;

        const searchParams = search ? { search } : {};
        const statusParams =
          __eq_status === 0
            ? { __eq_status: 0 }
            : __eq_status
            ? { __eq_status }
            : {};

        const productIdParams = __in_id && __in_id !== '[]' ? { __in_id } : {};

        return {
          url: `api/products`,
          method: 'GET',
          params: {
            ...searchParams,
            ...statusParams,
            ...productIdParams,
            ...restParams,
          },
          headers: {
            Authorization: `Bearer ${loginToken()}`,
          },
        };
      },
      providesTags: ['Product'],
    }),

    //for Asyc react select
    getProductsAll: builder.query({
      query: ({ selectProductParams }) => {
        const { search, selected_product_id, ...restParams } =
          selectProductParams;

        const searchParams = search ? { search } : {};

        const productIdParams = selected_product_id.length
          ? { selected_product_id }
          : '';

        return {
          url: `/api/products/all`,
          method: 'GET',
          params: {
            ...searchParams,
            ...productIdParams,
            ...restParams,
          },
          headers: {
            Authorization: `Bearer ${loginToken()}`,
          },
        };
      },
      // providesTags: ['Product'],
    }),

    getProductDetail: builder.query({
      query: ({ id }) => ({
        url: `api/products/${id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${loginToken()}`,
        },
      }),
      transformResponse: (response) => response.payload.data || {},
      providesTags: ['Product'],
      // keepUnusedDataFor: 3,
    }),

    updateProduct: builder.mutation({
      query: ({ fData, id }) => {
        const PARAMS = { _method: 'put' };
        return {
          url: `api/products/${id}`,
          method: 'POST',
          params: PARAMS,
          body: fData,
          headers: {
            Authorization: `Bearer ${loginToken()}`,
          },
        };
      },
      invalidatesTags: ['Product'],
    }),

    createProduct: builder.mutation({
      query: (payload) => {
        return {
          url: 'api/products',
          method: 'POST',
          body: payload,
          headers: {
            Authorization: `Bearer ${loginToken()}`,
          },
        };
      },
      invalidatesTags: ['Product'],
    }),

    deleteProduct: builder.mutation({
      query: (id) => ({
        url: `api/products/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${loginToken()}`,
        },
      }),
      invalidatesTags: ['Product'],
    }),

    importProducts: builder.query({
      query: () => ({
        url: '/api/products/import',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${loginToken()}`,
        },
      }),
      // transformResponse: (response) => response.payload.data || {},
      providesTags: ['Product'],
      // keepUnusedDataFor: 3,
    }),
    /*--------------------------- Customer Product fetch --------------------------------*/

    getAllCustomerProducts: builder.query({
      query: ({ params }) => {
        const { search, __eq_status, ...restParams } = params;

        const searchParams = search ? { search } : {};
        const statusParams =
          __eq_status === 0
            ? { __eq_status: 0 }
            : __eq_status
            ? { __eq_status }
            : {};

        return {
          url: 'api/customer/products',
          method: 'GET',
          params: { ...searchParams, ...statusParams, ...restParams },
          headers: {
            Authorization: `Bearer ${loginToken()}`,
          },
        };
      },
      // providesTags: ['Product'],
    }),

    getFeaturedProducts: builder.query({
      query: () => ({
        url: `api/customer/products/featured`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${loginToken()}`,
        },
      }),
      transformResponse: (response) => response.payload.data || {},
      providesTags: ['Product'],
    }),
    
    getSchedularLogs: builder.query({
      query: ({productsListReducer}) => ({
        url: `api/logs/products`,
        method: 'GET',
        params: productsListReducer.schedularLogsParams,
        headers: {
          Authorization: `Bearer ${loginToken()}`,
        },
      }),
      transformResponse: (response) => response.data || {},
      providesTags: ['Product'],
    }),
  }),
});

export const {
  useGetAllProductsQuery,
  useGetProductsAllQuery,
  useCreateProductMutation,
  useDeleteProductMutation,
  useGetProductDetailQuery,
  useUpdateProductMutation,
  useImportProductsQuery,
  /*---------- Customer Product ------------*/
  useGetAllCustomerProductsQuery,
  useGetFeaturedProductsQuery,
  useGetSchedularLogsQuery
} = productApi;
