import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import LoginReducer from '../pages/Login/LoginSlice';
import UserListReducer from '../pages/Users/usersSlice';
import ProductListReducer from '../pages/Products/productSlice';
import CustomerProductListReducer from '../pages/CustomerProducts/customerProductSlice';
import OrderListReducer from '../pages/Orders/orderSlice';
import CustomerOrderListReducer from '../pages/CustomerOrder/customerOrderSlice';

import { loginApi } from './services/loginApi';
import { userApi } from './services/userApi';
import { productApi } from './services/productApi';
import { orderApi } from './services/orderApi';

const reducers = {
  [loginApi.reducerPath]: loginApi.reducer,
  LoginReducer,
  [userApi.reducerPath]: userApi.reducer,
  UserListReducer,
  [productApi.reducerPath]: productApi.reducer,
  ProductListReducer,
  CustomerProductListReducer,
  [orderApi.reducerPath]: orderApi.reducer,
  OrderListReducer,
  CustomerOrderListReducer,
};

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(loginApi.middleware)
      .concat(userApi.middleware)
      .concat(productApi.middleware)
      .concat(orderApi.middleware),
});

setupListeners(store.dispatch);
