import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  params: {
    per_page: 25,
    page: 1,
    sort_order: 'desc',
    sort_id: `id`,
    search: '',
    __eq_status: 1,
  },
  limitOptions: [
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ],
  statusOptions: [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
  ],
};

export const CustomerProductsListSlice = createSlice({
  name: 'customerProductsListReducer',
  initialState,
  reducers: {
    productsListReducer: (state) => {
      state.params = initialState.params;
    },
    setDefaultProductParams: (state) => {
      state.params = initialState.params;
    },
    searchProduct: (state, action) => {
      state.params.search = action.payload;
    },
    toggleProductListFilter: (state, action) => {
      const name = action.payload.name;
      state.params[name] = action.payload.value;
      state.params['page'] = 1;
    },
    setDefaultProductListParam: (state, action) => {
      state.params = action.payload;
    },
    setEnterAmount: (state, action) => {
      state[`product.${action.payload.productId}`] = {
        productId: Number(action.payload.productId),
        amount: Number(action.payload.enterAmount),
      };
    },
    setDefaultAmount: (state, action) => {
      state[`product.${action.payload}`] = null;
    },
    setFeaturedEnterAmount: (state, action) => {
      state[`product.featured.${action.payload.productId}`] = {
        productId: Number(action.payload.productId),
        amount: Number(action.payload.enterAmount),
      };
    },
    setDefaultFeaturedAmount: (state, action) => {
      state[`product.featured.${action.payload}`] = null;
    },
    setPage: (state, action) => {
      state.params.page = action.payload;
    },
  },
});

export const {
  productsListReducer,
  searchProduct,
  toggleProductListFilter,
  setDefaultProductListParam,
  setDefaultProductParams,
  setEnterAmount,
  setDefaultAmount,
  setFeaturedEnterAmount,
  setDefaultFeaturedAmount,
  setProductId,
  setPage,
} = CustomerProductsListSlice.actions;

export default CustomerProductsListSlice.reducer;
