import React from 'react';

import { useGetUserProfileQuery } from '../../app/services/loginApi';
import './MobileNotifier.scss';

const MobileNotifier = () => {
  const { data } = useGetUserProfileQuery({
    skip: true,
  });

  const firstName = data?.payload?.data.first_name || 'User';

  return (
    <section className='mobile-landing'>
      <div className='container text-banner'>
        <div className='text'>
          <h1>
            Hello <span>{firstName},</span>
            <br />
            We are curently developing
            <br />
            Our mobile HQ platform
            <br />
            This portal is not mobile-friendly
          </h1>
        </div>
      </div>
    </section>
  );
};

export default MobileNotifier;
