import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loginToken: null,
  loggedIn: false,
};

export const LoginSlice = createSlice({
  name: 'loginReducer',
  initialState,
  reducers: {
    setLoginCredentials: (state, action) => {
      state.loginToken = action.payload;
      state.loggedIn = true;
    },
    onLogout: (state, action) => {
      state.loginToken = null;
      state.loggedIn = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoginCredentials, onLogout } = LoginSlice.actions;

export default LoginSlice.reducer;
