import React from 'react';

import Navbar from '../Navbar';

import './layout.scss';
// import Style from './layout.module.scss';

const Layout = ({ children, role }) => {
  return role === 'admin' ? (
    <main className='main'>
      <div>
        <Navbar role={role} />
      </div>

      <div className='page_wrapper'>{children}</div>
    </main>
  ) : (
    <section className={role === 'customer' ? 'customer-section' : ''}>
      <Navbar role={role} displayAccount />
      {children}
    </section>
  );
};

export default Layout;
