import React from "react";
import "./input.scss";
import clsx from "clsx";

const Input = ({
  className,
  inputType,
  placeholder,
  value,
  label,
  name,
  error,
  onChange,
  touched,
}) => {
  const hasError = error && touched;

  return (
    <div className="custom_login_form">
      <div className={clsx(`form-floating`, className, {
        hasError: hasError
      })}>
        <input
          name={name}
          type={inputType}
          className={clsx(`form-control`, {
            hasError: hasError
          })}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
        />
        <label className="my-auto d-flex align-items-center">{label}</label>
      </div>
      {hasError ? (
        <div className={hasError ? "error-msg" : "error-msg-disable"}>
          {error}
        </div>
      ) : null}
    </div>
  );
};

export default Input;
