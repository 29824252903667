import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import logoImg from '../../resources/images/logo.svg';
import Input from '../../components/Login/Input';
import Button from '../../components/FormComponents/Button';
import './forgetPassword.scss';
import { useSendResetPasswordLinkMutation } from '../../app/services/loginApi';
import { handleError, handleSuccess } from '../../app/helpers/errorHelpers';

const ForgetPassword = () => {
  const { t } = useTranslation();
  const [sendResetPasswordLink, response] = useSendResetPasswordLinkMutation();
  const { isLoading } = response;

  const { handleChange, handleSubmit, errors, touched, values } = useFormik({
    initialValues: {
      email: '',
    },
    enableReinitialize: true,

    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Must be a valid email.')
        .max(255)
        .required('The email field is required.'),
    }),
    onSubmit: (values, { resetForm }) => {
      sendResetPasswordLink(values)
        .unwrap()
        .then((res) => {
          if (res) {
            handleSuccess(res);
            resetForm({ values: '' });
          }
        })
        .catch((error) => {
          handleError(error);
        });
    },
  });

  const { email } = values;

  return (
    <section className='forget-section'>
      <div className='forget-section-container'>
        <div className='forget-form-wrapper'>
          <div className='logo'>
            <img src={logoImg} alt='no-image' className='img-fluid' />
          </div>
          <form onSubmit={handleSubmit} className='forget-form'>
            <div className='forget-field-wrapper'>
              <Input
                label={t('username_email')}
                inputType='email'
                name='email'
                placeholder='example@mail.com'
                value={email}
                error={errors && errors.email}
                touched={touched.email}
                onChange={handleChange}
              />
              {/* <Input
                label='Username'
                inputType='email'
                placeholder='example@mail.com'
                className='mb-3
              text-uppercase'
              /> */}
            </div>
            <div className='forget-button-wrapper'>
              <Button
                label={t('reset_password')}
                variant='solid'
                color='white'
                processing={isLoading}
              />
              {/*<Button title="Reset password" to="/" />*/}
            </div>
          </form>
          <div className='forget-link'>
            <Link
              to='/'
              className='align-items-center text-white text-decoration-none'
            >
              {t('have_account')}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgetPassword;
