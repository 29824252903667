import React from 'react';
import { NavLink } from 'react-router-dom';
// import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import logoImg from '../../resources/images/logo.svg';

import NavUserCard from './NavUserCard';
import './navbar.scss';

const Navbar = ({ displayAccount = false, role }) => {
  const { t } = useTranslation();
  return (
    <nav
      className='navbar navbar-expand-lg navbar-primary bg-primary
        custom_navbar'
    >
      <div className='container-fluid'>
        <a className='navbar-brand text-white' href='/'>
          <img src={logoImg} className='gamechest-logo' alt='no_image' />
        </a>

        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon' />
        </button>

        <div className='collapse navbar-collapse' id='navbarSupportedContent'>
          <ul className='navbar-nav mx-auto '>
            <li className='nav-item'>
              <NavLink to='/products' className='nav-link text-white'>
                {/* <a  aria-current="page"> */}
                {t('products')}
                {/* </a> */}
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink to='/orders' className='nav-link text-white'>
                {t('orders')}
              </NavLink>
            </li>
            {role === 'admin' && (
              <li className='nav-item'>
                <NavLink to='/users' className='nav-link text-white'>
                  {t('users')}
                </NavLink>
              </li>
            )}
          </ul>
          {displayAccount && <NavUserCard />}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
