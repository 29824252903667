import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  params: {
    per_page: 25,
    page: 1,
    sort_order: 'desc',
    sort_id: `id`,
    search: '',
    __eq_status: '',
    no_paginate: 0,
  },
  limitOptions: [
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ],
  statusOptions: [
    { value: 1, label: 'Active' },
    { value: 0, label: 'Inactive' },
  ],
  headerStatusOptions: [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
  ],
  roleOptions: [
    { value: 'admin', label: 'Admin' },
    { value: 'customer', label: 'Customer' },
  ],
  formInitialValues: {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    role: '',
    password: '',
    password_confirmation: '',
    status: '',
    company_name: '',
    company_number: '',
    vat_number: '',
    address: '',
    zip_code: '',
    city: '',
    war_reference: '',
    your_reference: '',
    description: '',
    products: [
      {
        product_id: '',
        product_name: '',
      },
    ],
    image:''
  },
};

export const UsersListSlice = createSlice({
  name: 'usersListReducer',
  initialState,
  reducers: {
    usersListReducer: (state) => {
      state.params = initialState.params;
    },
    setDefaultParams: (state) => {
      state.params = initialState.params;
    },
    searchUser: (state, action) => {
      state.params.search = action.payload;
    },
    toggleUserListFilter: (state, action) => {
      const name = action.payload.name;
      state.params[name] = action.payload.value;
      state.params['page'] = 1;
    },
    setDefaultForm: (state) => {
      state.formInitialValues = initialState.formInitialValues;
    },
    setNoPaginateUsers: (state) => {
      state.params.no_paginate = 1;
    },
    setPage: (state, action) => {
      state.params.page = action.payload;
    },
    onRemoveInputField: (state, action) => {
      const index = action.payload.index;
      state.formInitialValues.products.splice(index, 1);
    },
    setUpdateForm: (state, action) => {
      const {
        user_detail,
        email,
        first_name,
        last_name,
        phone_number,
        status,
        roles,
        image
      } = action?.payload?.userDetails || {};

      const {
        address,
        city,
        company_name,
        company_number,
        description,
        vat_number,
        war_reference,
        your_reference,
        zip_code,
      } = user_detail;

      const newArr = action?.payload?.newArr || [];

      const updateState = {
        address: address,
        city: city,
        company_name: company_name,
        company_number: company_number,
        description: description,
        vat_number: vat_number,
        war_reference: war_reference,
        your_reference: your_reference,
        zip_code: zip_code,
        role: roles[0].name,
        email: email,
        first_name: first_name,
        last_name: last_name,
        phone_number: phone_number,
        status: status,
        password: '',
        password_confirmation: '',
        products: newArr.length ? newArr : state.formInitialValues.products,
        image:image
      };
      state.formInitialValues = updateState;
    },
  },
});

export const {
  usersListReducer,
  setDefaultParams,
  searchUser,
  toggleUserListFilter,
  setDefaultForm,
  setUpdateForm,
  setNoPaginateUsers,
  setPage,
  onRemoveInputField,
} = UsersListSlice.actions;

export default UsersListSlice.reducer;
