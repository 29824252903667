import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { baseUrl } from '../config';
import { loginToken } from '../utils/local-storage-utils';

export const loginApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  reducerPath: 'loginApi',
  refetchOnReconnect: true,
  tagTypes: ['Login'],

  endpoints: (builder) => ({
    createLogin: builder.mutation({
      query: (payload) => {
        return {
          url: 'api/login',
          method: 'POST',
          body: payload,
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        };
      },
      invalidatesTags: ['Login'],
    }),

    createLogout: builder.mutation({
      query: () => {
        return {
          url: `api/logout`,
          method: 'POST',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            Authorization: `Bearer ${loginToken()}`,
          },
        };
      },
      // invalidatesTags: ['Login'],
    }),

    getUserProfile: builder.query({
      query: () => ({
        url: 'api/profile',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${loginToken()}`,
        },
      }),
      providesTags: ['Login'],
    }),

    updateAccount: builder.mutation({
      query: (payload) => {
        const PARAMS = { _method: 'put' };
        return {
          url: 'api/profile',
          method: 'POST',
          params: PARAMS,
          body: payload,
          headers: {
            // "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${loginToken()}`,
          },
        };
      },
      invalidatesTags: ['Login'],
    }),

    sendResetPasswordLink: builder.mutation({
      query: (values) => {
        return {
          url: '/api/reset/password/link',
          method: 'POST',
          body: values,
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        };
      },
      invalidatesTags: ['Login'],
    }),

    verifyResetPasswordToken: builder.query({
      query: ({ token }) => ({
        url: `api/reset/${token}`,
        method: 'GET',
      }),
      // providesTags: ['Login'],
    }),

    resetPassword: builder.mutation({
      query: (values) => {
        return {
          url: '/api/reset/password',
          method: 'POST',
          body: values,
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        };
      },
      invalidatesTags: ['Login'],
    }),

    activateEmail: builder.query({
      query: ({ token }) => ({
        url: `/api/activate/${token}`,
        method: 'GET',
      }),
      providesTags: ['Login'],
    }),
  }),
});
export const {
  useCreateLoginMutation,
  useGetUserProfileQuery,
  useCreateLogoutMutation,
  useUpdateAccountMutation,
  useSendResetPasswordLinkMutation,
  useVerifyResetPasswordTokenQuery,
  useResetPasswordMutation,
  useActivateEmailQuery,
} = loginApi;
