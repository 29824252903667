import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  params: {
    per_page: 25,
    page: 1,
    sort_order: 'desc',
    sort_id: `id`,
    search: '',
    __eq_status: '',
  },
  limitOptions: [
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ],
  statusOptions: [
    { value: 'pending', label: 'Pending' },
    { value: 'approved', label: 'Approved' },
  ],
  formInitialValues: {
    products: [
      {
        product_id: '',
        qty: '',
        product_name: '',
        product_price: null,
        total: null,
        actualProductQuantity: '',
      },
    ],
    status: '',
    user_id: '',
    description: '',
  },
};

export const OrdersListSlice = createSlice({
  name: 'ordersListReducer',
  initialState,
  reducers: {
    ordersListReducer: (state) => {
      state.params = initialState.params;
    },
    setDefaultOrderParams: (state) => {
      state.params = initialState.params;
    },
    searchOrder: (state, action) => {
      state.params.search = action.payload;
    },
    toggleOrderListFilter: (state, action) => {
      const name = action.payload.name;
      state.params[name] = action.payload.value;
      state.params['page'] = 1;
    },
    setDefaultOrderListParam: (state, action) => {
      state.params = action.payload;
    },
    setDefaultForm: (state) => {
      state.formInitialValues = initialState.formInitialValues;
    },
    setUpdateForm: (state, action) => {
      const { status, user, description } = action.payload.orderDetails;
      const userId = user?.id || '';

      const selectedProducts = action?.payload?.data;

      const updateState = {
        products: selectedProducts.length
          ? selectedProducts
          : state.formInitialValues.products,
        user_id: userId,
        status: status || '',
        description: description || '',
      };
      state.formInitialValues = updateState;
    },
    onRemoveInputField: (state, action) => {
      const index = action.payload.index;
      state.formInitialValues.products.splice(index, 1);
    },
    setPage: (state, action) => {
      state.params.page = action.payload;
    },
  },
});

export const {
  ordersListReducer,
  searchOrder,
  toggleOrderListFilter,
  setDefaultOrderListParam,
  setDefaultOrderParams,
  setDefaultForm,
  setUpdateForm,
  onRemoveInputField,
  setPage,
} = OrdersListSlice.actions;

export default OrdersListSlice.reducer;
