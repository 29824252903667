import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  params: {
    per_page: 25,
    page: 1,
    sort_order: 'desc',
    sort_id: `id`,
    search: '',
    status: '',
  },
  limitOptions: [
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ],
  statusOptions: [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
  ],
};

export const CustomerOrdersListSlice = createSlice({
  name: 'customerOrdersListReducer',
  initialState,
  reducers: {
    ordersListReducer: (state) => {
      state.params = initialState.params;
    },
    setDefaultOrderParams: (state) => {
      state.params = initialState.params;
    },
    searchOrder: (state, action) => {
      state.params.search = action.payload;
    },
    toggleOrderListFilter: (state, action) => {
      const name = action.payload.name;
      state.params[name] = action.payload.value;
      state.params['page'] = 1;
    },
    setDefaultOrderListParam: (state, action) => {
      state.params = action.payload;
    },
    setPage: (state, action) => {
      state.params.page = action.payload;
    },
  },
});

export const {
  ordersListReducer,
  searchOrder,
  toggleOrderListFilter,
  setDefaultOrderListParam,
  setDefaultOrderParams,
  setPage,
} = CustomerOrdersListSlice.actions;

export default CustomerOrdersListSlice.reducer;
